<template>
  <div class="appInner">
    <form 
      ref="form"
      @submit.prevent 
      action=""
      method="POST"
    >
    </form>
  </div>
</template>

<script>
const FUJITSU_USAGE_FRONT_DOMAIN = process.env.VUE_APP_FUJITSU_USAGE_FRONT_DOMAIN
const FUJITSU_CLIENT_ID = process.env.VUE_APP_FUJITSU_CLIENT_ID
const FUJITSU_CLIENT_SECRET = process.env.VUE_APP_FUJITSU_CLIENT_SECRET
export default {
  name: "OpenNewWindowView",
  components: {
    
  },
  data() {
    return {
      usageReferenceUrl: "",
    };
  },
  methods: {
    submitForm() {
      // クエリパラメータからline_uidを取得
      const lineId = this.$route.query.line_uid;
      // フォームの設定
      this.$refs.form.action = `${FUJITSU_USAGE_FRONT_DOMAIN}/circulation-status`;
      // クライアントIDの設定
      let clientIdInput = document.createElement('input');
      clientIdInput.setAttribute('type', 'hidden');
      clientIdInput.setAttribute('name', 'client_id');
      clientIdInput.setAttribute('value', FUJITSU_CLIENT_ID);
      this.$refs.form.appendChild(clientIdInput);
      // クライアントシークレットの設定
      let clientSecretInput = document.createElement('input');
      clientSecretInput.setAttribute('type', 'hidden');
      clientSecretInput.setAttribute('name', 'client_secret');
      clientSecretInput.setAttribute('value', FUJITSU_CLIENT_SECRET);
      this.$refs.form.appendChild(clientSecretInput);
      // line_uidの設定
      let lineUidInput = document.createElement('input');
      lineUidInput.setAttribute('type', 'hidden');
      lineUidInput.setAttribute('name', 'line_uid');
      lineUidInput.setAttribute('value', lineId);
      this.$refs.form.appendChild(lineUidInput);
      // フォームの送信
      this.$refs.form.submit();
    },
  },
  mounted() {
    this.submitForm();
  }
};
</script>

<style>
</style>