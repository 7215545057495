<template>
  <v-app class="pa-0 ma-0">
    <LoadingView style="position: absolute; top : 0" v-if="isLoading"/>
    <div id="app">
      <router-view />
      <footer></footer>
    </div>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import LoadingView from "@/components/LoadingView/index.vue";
import liff from "@line/liff";
import {
  ID_TOKEN_LOGIN,
} from '@/store/action-types';
import {
  SET_USER_PARAMETER,
  SET_LINE_ID,
  SET_LOGIN_ERROR,
} from '@/store/mutation-types'
export default {
  components: {
    LoadingView,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  async created() {
    this.isLoading = true;
    try{
      // ユーザのID TOKENを取得
      await this.getLineIdToken();
      // 取得したIDTOKENをバックエンドに送り、ユーザの図書館利用者番号を取得
      // this.setUserParameter({id_token : '1234567890'})
      if(!this.user.id_token){
        this.setLoginError('LINE情報の認証に失敗しました。\n一度アプリを閉じてからもう一度お試しください。')
      }else {
        await this.idTokenLogin(this.user.id_token)
      }
      if(this.user?.user_code && this.user?.user_code?.length === 8){
        if (this.$route.path != '/openNewWindow') {
          this.$router.push('/');
        }
      }
      else{
        console.log('未ログインのユーザー')
      }
    }
    catch{
      this.setLoginError('不明なエラーが発生しました。\n一度画面を閉じてからもう1度お試しください')
    }
    finally{
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      idTokenLogin: ID_TOKEN_LOGIN,
    }),
    ...mapMutations({
      setUserParameter: SET_USER_PARAMETER,
      setLineId: SET_LINE_ID,
      setLoginError: SET_LOGIN_ERROR,
    }),
    async getLineIdToken() {
      // LINEのUID取得処理
      await Promise.all([
        await liff
          .init({
            liffId: process.env.VUE_APP_LIFF_ID,
            // withLoginOnExternalBrowser: true
          })
          .then(async () => {
            if (!liff.isLoggedIn()) {
              // 利用状況参照画面を開く際はLIFFログインを行わない
              if (this.$route.path === '/openNewWindow') {
                return;
              }
              liff.login();
            }
            // storeにidTokenとuidを保存
            const idToken = liff.getIDToken();
            const uid = liff.getDecodedIDToken().sub;
            
            const testmsg = idToken ? idToken : ''
            this.setUserParameter({id_token : testmsg})
            this.setLineId(uid);
          })
          .catch(async (error) => {
            console.log(error)
          }),
      ]);
    },
  },
};
</script>

<style>
html,
body {
  overflow: hidden;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: none;
  font-style: normal;
  text-align: left;
  zoom: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

table {
  border-collapse: collapse;
  font-family: inherit;
}

h1,
h2,
h3,
h4,
h5 {
  font-size: 100%;
  font-weight: normal;
  line-height: 1;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: 16px;
}

input[type="button"],
input[type="text"],
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0;
}

textarea {
  resize: none;
  -webkit-appearance: none;
  border-radius: 0;
}

th,
td {
  border-collapse: collapse;
}

table th,
table td {
  white-space: nowrap;
}

ul,
ol {
  list-style-type: none;
}

img {
  vertical-align: text-bottom;
  vertical-align: -webkit-baseline-middle;
  max-width: 100%;
  height: auto;
  width: auto;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Noto Sans JP", sans-serif;
}

html,
body,
#app {
  height: 100% !important;
}

#app {
  display: flex;
  flex-direction: column;
}

.loadingDisplayBody,
.cameraAccessErrorDialogBody,
.initialStartDialogBody,
.inputLibraryIdConfirmDialogBody,
.isPutUserIdDialogBody {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputLibraryIdConfirmDialogInner,
.initialStartDialogInner,
.cameraAccessErrorDialogInner,
.isPutUserIdDialogInner {
  background: #fff;
  padding: 16px 30px;
  max-width: 96%;
  border-radius: 25px;
}

.initialStartDialogInner {
  padding: 2vh 2vw;
}

.initialStartDialogInner h2,
.cameraAccessErrorDialogInnerTitle h2,
.inputLibraryIdConfirmDialogInner h2,
.isPutUserIdDialogInner h2 {
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
}

.initialStartDialogInner .defaultButton,
.cameraAccessErrorDialogInner .defaultButton,
.inputLibraryIdConfirmDialogInner .defaultButton,
.isPutUserIdDialogInner .defaultButton {
  border: solid 1px #000;
  background: #e8f7fa;
  border-radius: 5px;
  padding: 4px 8px;
  height: 38px;
  margin: 16px auto 0;
  text-align: center;
}

.initialStartDialogInner p,
.cameraAccessErrorDialogInner img,
.inputLibraryIdConfirmDialogInner p,
.cameraAccessErrorDialogInner p,
.isPutUserIdDialogInner p {
  font-size: 0.85rem;
}

.putUserIdNewBody .readArea p,
.isPutUserIdDialogBody .readArea p {
  font-size: 0.65rem;
  margin: 0;
}</style>