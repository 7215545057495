<template>
  <div style="min-width: 100vw; min-height: 100vh; z-index: 99999; background-color: rgba(0, 0, 0, 0.801);">
    <div style="min-width: 100%; text-align:center; color:white; margin-top:40vh">
      <v-progress-circular indeterminate :size="120" color="white" />
      <div style="min-width: 100%;text-align:center;">
        読み込み中です{{ tententen }}
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "LoadingView",
  props: [''],
  data() {
    return {
      number: 0,
    };
  },
  components: {
  },
  watch: {
    number(number){
      if(number > 3){
        this.number = 0
      }
    }
  },
  computed: {
    tententen(){
      return '.'.repeat(this.number);
    }
  },
mounted() {
  setInterval(() => {
      this.number += 1;
    }, 1000);
},
methods: {
}
  };
</script>
<style scoped></style>