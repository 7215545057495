<template>
  <div style="min-height: 100%;">
    <StandardHeader></StandardHeader>
    <div style="min-height: 100%; position: relative;">
      <v-card elevation="0" style="max-width: 90vw; max-height: 38vh; margin-top: 40%; margin-left: auto; margin-right: auto; position: absolute; ; right: 0; left: 0;">
        <LoginCard/>
      </v-card>
    </div>
  </div>
</template>

<script>
import StandardHeader from "@/components/HeaderView/StandardHeader.vue"
import LoginCard from "@/components/Login/LoginCard.vue"
import {
  LOADING_START,
  LOADING_STOP,
  PUT_USERID_DB_TRUE,
  PUT_USERID_DB_FALSE,
  SAVING_LIBRARY_USER_ID,
  SAVING_CURRENT_COMPONENT,
  CODABAR_SCANNER_DISPLAY,
  SAVING_INPUT_LIBRARY_ID,
} from "@/store/action-types";
import { mapState, mapActions } from "vuex";

export default {
  name: "LoginView",
  data() {
    return {
      libraryUserIdNew: this.inputLibraryId,
      idToken: null,
      libraryUserIdMaxLength: 8,
    };
  },
  components: {
    StandardHeader,
    LoginCard,
  },
  watch: {
    inputLibraryId() {
      this.libraryUserIdNew = this.inputLibraryId;
    },
    libraryUserIdNew(val) {
      this.libraryUserIdNew = val.replace(/[^0-9]/g, '');
      this.savingInputLibraryId(val);
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
      isPutUserIdDB: (state) => state.isPutUserIdDB,
      libraryUserId: (state) => state.libraryUserId,
      errorMessage: (state) => state.errorMessage,
      currentComponent: (state) => state.currentComponent,
      isCodabarScannerStatus: (state) => state.isCodabarScannerStatus,
      inputLibraryId: (state) => state.inputLibraryId,
    }),
    isRegister() {
      if (this.libraryUserIdNew != null) {
        return this.chackValidate(this.libraryUserIdNew);
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.loadingStop();
  },
  methods: {
    ...mapActions({
      loadingStart: LOADING_START,
      loadingStop: LOADING_STOP,
      putUseridDbTrue: PUT_USERID_DB_TRUE,
      putUseridDbFalse: PUT_USERID_DB_FALSE,
      savingLibraryUserId: SAVING_LIBRARY_USER_ID,
      savingCurrentComponent: SAVING_CURRENT_COMPONENT,
      codabarScannerDisplay: CODABAR_SCANNER_DISPLAY,
      savingInputLibraryId: SAVING_INPUT_LIBRARY_ID,
    }),
    async changeCurrentComponent(state) {
      this.savingCurrentComponent(state);
      this.$emit("startScan");
    },
    chackValidate(id) {
      if (id.length != this.libraryUserIdMaxLength) {
        return false;
      }
      if (!id.match(/^[0-9]+$/)) {
        return false;
      }
      return true;
    },
    async openInputLibraryIdConfirmDialog() {
      this.$emit("openInputLibraryIdConfirmDialog", this.libraryUserIdNew);
    },
    openCodabarScanner() {
      this.codabarScannerDisplay();
      this.$emit("startScan");
    },
  },
};
</script>
<style scoped></style>