<template>
  <v-card>
    <v-container>
      <div class="mt-2 mb-2" style="text-align: center;font-size: 20px; font-weight: bold;">
        デジタル会員証<br>ログイン
      </div>

      <v-text-field :readonly="loading" v-model="username" prepend-inner-icon="mdi-account-circle-outline" placeholder="ID" outlined />

      <v-text-field :readonly="loading" v-model="password" prepend-inner-icon="mdi-shield-key-outline" placeholder="パスワード" outlined />

      <v-card-text class="ma-0 pa-0" v-if="loginErrorMessage" style="color:red; white-space: pre-wrap;">
        {{ loginErrorMessage }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="#42a5f5" style="color: white;" @click="send" :loading="loading">
          <v-icon left>mdi-login</v-icon>
          ログイン
        </v-btn>
        <v-btn :loading="loading" v-if="showCancelBtn" @click="$emit('cancel')">
          キャンセル
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { 
  mapState, 
  mapActions, 
  // mapMutations,
} from "vuex";
import {
  USER_INFO_LOGIN,
} from '@/store/action-types';
export default {
  name: "LoginCard",
  props:['showCancelBtn'],
  data() {
    return {
      loading : false,
      // errMsg : '',
      // 
      username : '',
      password : '',
    };
  },
  components: {
  },
  watch: {
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      loginErrorMessage: (state) => state.loginErrorMessage,
    }),
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      userInfoLogin: USER_INFO_LOGIN,
    }),
    async send(){
      this.loading = true;
      // this.errMsg = ''
      await this.userInfoLogin({username : this.username, password : this.password})
      if(this.user.user_code){
        this.$router.push('/');
      }
      // else{
      //   this.errMsg = 'IDまたはパスワードが違います'
      // }
      this.loading = false;
    },
  },
};
</script>
<style scoped></style>