<template>
  <v-dialog v-model="visible" persistent style="min-width: 90vw">
    <v-card>
      <LoginCard :showCancelBtn="true" @cancel="hide"/>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from "vue";
import Dialog from "@/plugins/loginDialog";
import LoginCard from "@/components/Login/LoginCard.vue"
export default Vue.extend({
  data() {
    return {
      visible: false,
    };
  },
  components:{
    LoginCard,
  },
  beforeMount() {
    // here we need to listen for emited events
    // we declared those events inside our plugin
    Dialog.EventBus.$on("show", (params) => {
      this.show(params);
    });
  },
  computed: {
  },
  methods: {
    hide() {
      this.visible = false;
    },
    show() {
      // making modal visible
      this.visible = true;
    },
  },
});
</script>