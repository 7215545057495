// import {
// YOUBI_LIST,
// } from '@/constants/index.js';

import store from "@/store/index";
// import {
// SET_SIGN_IN_USER_SESSION
// } from "@/store/mutation-types";

// サインイン状態か確認する
export const confirmSignIn = async () => {
    return store?.state?.user?.user_code ? true : false
};